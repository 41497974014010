import React from 'react';
import { Form } from 'antd';
import LoginForm from './components/LoginForm';
import './App.css';
import { BrowserRouter, Route, Switch } from "react-router-dom";

const App: React.FC = () => {
  const Login = Form.create({ name: 'normal_login' })(LoginForm);
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/:service?" component={Login} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
